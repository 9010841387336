import React, { Component } from "react";
import "./Header.scss";
import Logo from "../../../static/assets/images/bolttech_logo.png";
import {siteLogo} from "../../../data/SiteConfig";
class Header extends Component {
  render() {
    var divStyle = {
      backgroundImage: 'url(' + siteLogo + ')',
    };
    return (
      <header className="header">
        <div className="logo" style={divStyle}></div>
      </header>
    );
  }
}

export default Header;
